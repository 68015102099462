//Colors Light Mode
$blue_principal: #1B75BC;
$letters: black;
//$background: white;

//Colors Dark Mode
$letters_dark_mode: white;
//$background_dark: #231F20;

$drawer-width: 240px;

