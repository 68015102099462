@import '../abstracts/variables';
@import '../abstracts/placeholders';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #fafafa;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto !important;
}

.content-wrapper {
  padding-left: $drawer-width;
  overflow-y: scroll;
  width: calc(100% - #{$drawer-width});
  padding-bottom: 50px;
  display: flex;
}

.buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.MuiButtonBase-root:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.form {
  .MuiTextField-root, .MuiAutocomplete-hasPopupIcon, .MuiFormControlLabel-root {
    margin-bottom: 20px;
    width: 70%;
  }

  .MuiAutocomplete-hasPopupIcon {
    .MuiTextField-root {
      width: 100%;
    }
  }
}

.section-title {
  font-size: 2.5rem;
  align-self: center;
  margin: 10px 0;
}

.card {
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: rgba(103, 101, 101, 0.15) 4px 4px 25px;
}

@media (max-width: 900px) {
  .content-wrapper {
    padding-left: 0;
    width: 100%;
    justify-content: center;
  }

  .section-title {
    font-size: 2rem;
  }
}

